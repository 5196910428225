.footer {
  margin-top: 120px;
  /*  bottom: 80px; */
  background-color: #ffffff;
  position: relative;
  padding: 0.7rem 0 0.7rem 0;
  height: var(--footer-size);
}

.footer > ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.icon:hover {
  transform: scale(1.3);
}

/* .footer > ul > li > a, a:link, a:visited a:hover a:active {
  text-decoration: none;
  text-decoration-color:currentColor;
  color: currentColor;
} */
