.main {
  margin-top: 6rem;
  margin-left: 5rem;
}

.section {
  margin-top: 1rem;
}

.section-heading {
  font-size: 150%;
  margin-left: 5rem;
  margin-bottom: 2.8rem;

}

.publication-item {

  padding: 1rem 5rem;
  text-align: justify;
  text-justify: inter-word;
}

p.publication-item:hover {

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.bold-author {
  font-weight: bold;
}

a.metadata,
a:link,
a:focus,
a:hover,
a:visited {
  text-decoration: underline;
  color: currentColor;
}
