


.hero-container {
  /* width: 100vw; */
  /* height: 100vh; */
  background-image: url("../../../public/img/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover; /* ajustar width de la imagen al del padre: elemento root o body, bien en esta prop o en la que sea*/
  position: relative;
  /* top: var(--navbar-size); */
  background-position: center, center;
  overflow: hidden;
}

.hero-overlay {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-variant: small-caps;
}

h1 {

  font-weight: 400;
  font-size: 2.20rem;
  text-shadow: 2px 4px 0px rgba(0,0,0,0.2);
  /*snippet de animación del título desde aqui*/
  transform: scale(0.90);
  animation: gemasname 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes gemasname {
  100% {
    transform: scale(1);
  }
}


h1 > span {
  display: inline-block;
  opacity: 0.4;
  filter: blur(3px);
  padding:0.4rem;
}

h1 > span:nth-child(1) {
  animation: fade-in 1s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

h1 > span:nth-child(2) {
  animation: fade-in 1.5s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

h1 > span:nth-child(3) {
  animation: fade-in 2s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

h1 > span:nth-child(4) {
  animation: fade-in 2s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}


@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

/*hasta aqui snippet de animación del título */

@keyframes subtitle {
  from {left: -300px;}
  to {left: 10px;}
}
.subtitle {
  position: relative;
  top:-1.25rem;
  font-size: 0.94rem;
/*   transform: subtitle(0.94); */
  animation: subtitle 1.6s  forwards cubic-bezier(0.5, 1, 0.89, 1);
  padding-right:1rem;
}

