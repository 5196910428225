@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@500&display=swap');


/* No está importando la font DM-Sans */

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: "Karla", "Open Sans",   "Noto Sans", "DM-Sans",  Verdana,  "Times New Roman";
  box-sizing: border-box;
  color: #06283d;
  background-color: #eaf6f6;
}

:root {
  --navbar-size: 60px;
  --footer-size: 60px;
}

#root {
  height: 100vh;
}

.main {
  padding-top: 5rem;
}
